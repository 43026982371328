/** @jsx jsx */
import { useMemo } from 'react';
import { Box, jsx, ThemeUIStyleObject } from 'theme-ui';
import { MenuOption as MenuOptionType } from '../types';
import { MenuOption } from './MenuOption';

function getContainerStyles(isActive: boolean): ThemeUIStyleObject {
  return {
    opacity: isActive ? 1 : 0,
    pointerEvents: isActive ? 'all' : 'none',
    position: 'absolute',
    inset: 0,
  };
}

const LIST_STYLES: ThemeUIStyleObject = {
  listStyle: 'none',
  paddingInlineStart: '30px',
  height: '100%',
  overflow: 'auto',
};

export const MenuOptionList = ({
  items,
  isActive,
  onChange,
}: {
  items: MenuOptionType[];
  isActive: boolean;
  onChange: (value: string) => void;
}) => {
  const containerStyles = useMemo(
    () => getContainerStyles(isActive),
    [getContainerStyles, isActive],
  );
  return (
    <Box sx={containerStyles}>
      <Box as="ul" sx={LIST_STYLES}>
        {items.map(item => {
          return (
            <MenuOption key={item.value} item={item} onChange={onChange} />
          );
        })}
      </Box>
    </Box>
  );
};
