import React, { useCallback } from 'react';
import { Checkbox, Label, Text, ThemeUIStyleObject } from 'theme-ui';
import { MenuOption as MenuOptionType } from '../types';

const LABEL_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  alignItems: 'center',
};
const LABEL_TEXT_STYLES: ThemeUIStyleObject = {
  lineHeight: '40px',
};

export const MenuOption = ({
  item,
  onChange,
}: {
  item: MenuOptionType;
  onChange: (value: string) => void;
}) => {
  const handleChange = useCallback(
    () => onChange(item.value),
    [onChange, item.value],
  );
  return (
    <li key={item.value}>
      <Label sx={LABEL_STYLES}>
        <Checkbox
          value={item.value}
          checked={item.isRefined}
          onChange={handleChange}
        />
        <Text sx={LABEL_TEXT_STYLES}>{item.label}</Text>
      </Label>
    </li>
  );
};
