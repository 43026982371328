import { ThemeUIStyleObject } from 'theme-ui';

export const INNER_CONTAINER_STYLES: ThemeUIStyleObject = {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '500px',
};
export const ICON_STYLES: ThemeUIStyleObject = {
  width: '200px',
  height: '200px',
};
export const TITLE_STYLES: ThemeUIStyleObject = {
  margintop: '60px',
  fontSize: '28px',
  fontWeight: 700,
};
export const SUBTITLE_STYLES: ThemeUIStyleObject = {
  fontSize: '20px',
};
