/** @jsx jsx */
import { Box, Flex, jsx, ThemeUIStyleObject } from 'theme-ui';
import { useCurrentRefinements } from 'react-instantsearch-hooks-web';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import { Container } from '@sprinklr/shared-lib';
import { useCallback } from 'react';

const CONTAINER_STYLES: ThemeUIStyleObject = {
  alignItems: 'center',
  gap: '8px',
  flexWrap: 'wrap',
  marginBlockStart: '32px',
};
const BUTTON_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  color: 'darkGrey',
  borderRadius: '4px',
  fontSize: '14px',
  lineHeight: '18px',
  display: 'flex',
  fontWeight: '600',
  alignItems: 'center',
  gap: '6px',
  background: 'muted',
  padding: '4px 8px',
};
const ICON_STYLES: ThemeUIStyleObject = {
  flexShrink: 0,
};
const OUTER_CONTAINER_STYLES = {
  paddingBlock: 0,
};

type FilterRefinement = {
  label: string;
  type:
    | 'facet'
    | 'exclude'
    | 'disjunctive'
    | 'hierarchical'
    | 'numeric'
    | 'query'
    | 'tag';
  attribute: string;
  value: string | number;
};

const FilterLabel = ({
  refinement,
  onClick,
}: {
  refinement: FilterRefinement;
  onClick: (refinement: FilterRefinement) => void;
}) => {
  const handleClick = useCallback(
    () => onClick(refinement),
    [onClick, refinement],
  );
  return (
    <button sx={BUTTON_STYLES} onClick={handleClick}>
      <Box>{refinement.label}</Box>
      <Icon iconName="cross" iconSx={ICON_STYLES} />
    </button>
  );
};

export const AppliedFilterLabels = () => {
  const { refine, items } = useCurrentRefinements();
  if (items.length <= 0) {
    return null;
  }

  return (
    <Container sx={OUTER_CONTAINER_STYLES}>
      <Flex sx={CONTAINER_STYLES}>
        {items.flatMap(item =>
          item.refinements.map(refinement => {
            return (
              <FilterLabel
                key={`${refinement.attribute}:${refinement.label}`}
                refinement={refinement}
                onClick={refine}
              />
            );
          }),
        )}
      </Flex>
    </Container>
  );
};
