import { ThemeUIStyleObject } from 'theme-ui';

export const CONTAINER_STYLES: ThemeUIStyleObject = {
  height: '100%',
};
export const MENU_BUTTON_LIST_CONTAINER_STYLES: ThemeUIStyleObject = {
  height: '100%',
  backgroundColor: '#F1F1F1',
  width: '130px',
};
export const OPTION_LIST_CONTAINER_STYLES: ThemeUIStyleObject = {
  height: '100%',
  flex: 1,
  position: 'relative',
};
