/** @jsx jsx */

//lib
import { jsx } from 'theme-ui';

//components
import { ContainerPadding } from '@sprinklr/shared-lib/@types/types';
import { StoryCardProps } from '@sprinklr/shared-lib/components/card/types';
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import { getSearchClient } from '../../../utils/algoliaClient';
import CustomerStoriesGrid from './components/CustomerStoriesGrid';
import {
  DEFAULT_SEARCH_INDEX,
  HITS_PER_PAGE,
  NO_LOCALE_PADDING,
} from './constants';
import { CardFullDisplay } from '@sprinklr/shared-lib';
import NoLocaleContentTemplate from '../noLocaleContent/NoLocaleContent';
import { useContext } from 'react';
import PageContext from '@sprinklr/shared-lib/context/pageContext';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

type CustomerStoryProps = {
  headline?: string;
  description?: string;
  storyCards?: StoryCardProps[];
  padding: ContainerPadding;
  pageContext;
};

// Algolia search currently implemented only for en-US.
const ALGOLIA_ENABLED_LOCALES = ['en-US'];

const CustomerStories = ({ storyCards, padding }: CustomerStoryProps) => {
  const { nodeLocale } = useContext(PageContext);

  const [pt, pb] = useContainerPadding(padding);
  if (!ALGOLIA_ENABLED_LOCALES.includes(nodeLocale)) {
    return storyCards?.length ? (
      <CardFullDisplay
        pt={pt}
        pb={pb}
        cards={storyCards}
        overallPageType={'story'}
      />
    ) : (
      <NoLocaleContentTemplate padding={NO_LOCALE_PADDING} />
    );
  }

  const searchClient = getSearchClient();
  if (!searchClient) {
    return null;
  }
  return (
    <InstantSearch indexName={DEFAULT_SEARCH_INDEX} searchClient={searchClient}>
      <Configure hitsPerPage={HITS_PER_PAGE} />
      <CustomerStoriesGrid />
    </InstantSearch>
  );
};

export default CustomerStories;
