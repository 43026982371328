/** @jsx jsx */
import { useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { Box, jsx } from 'theme-ui';
import { FiltersBar } from '../FiltersBar';
import { NoResultsBoundary } from '../NoResultsBoundary';
import { NoResultsFallback } from '../NoResultsFallback/NoResultsFallback';
import { ResultsGrid } from '../ResultsGrid';
import SearchBox from '../SearchBox';
import { BackToTopCTA } from '../BackToTopCTA';
import { FilterDrawer } from '../FilterDrawer';
import {
  FILTERS_BAR_CONTAINER_STYLES,
  OVERLAY_STYLES,
  RESULTS_GRID_CONTAINER_STYLES,
  APPLIED_FILTER_LABELS_STYLES,
} from './styles';
import { INTERSECTION_OPTIONS } from './constants';
import { AppliedFilterLabels } from '../AppliedFilterLabels';

const CustomerStoriesGrid = () => {
  const filtersBarIntersectionRef = useRef<HTMLDivElement | null>(null);
  const filtersBarIntersection = useIntersection(
    filtersBarIntersectionRef,
    INTERSECTION_OPTIONS,
  );
  const isFiltersBarVisible = filtersBarIntersection?.isIntersecting ?? true;

  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Box>
      <SearchBox />
      <Box ref={filtersBarIntersectionRef} sx={FILTERS_BAR_CONTAINER_STYLES}>
        <FiltersBar onDropdown={setShowOverlay} />
      </Box>
      <Box sx={APPLIED_FILTER_LABELS_STYLES}>
        <AppliedFilterLabels />
      </Box>
      <Box sx={RESULTS_GRID_CONTAINER_STYLES}>
        <NoResultsBoundary fallback={<NoResultsFallback />}>
          <ResultsGrid />
          <BackToTopCTA
            isVisible={!isFiltersBarVisible}
            scrollToRef={filtersBarIntersectionRef}
          />
          {showOverlay ? <Box sx={OVERLAY_STYLES} /> : null}
        </NoResultsBoundary>
      </Box>
      <FilterDrawer />
    </Box>
  );
};

export default CustomerStoriesGrid;
