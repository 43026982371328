/** @jsx jsx */
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchBox } from 'react-instantsearch-hooks-web';
import { Box, jsx } from 'theme-ui';
import { SEARCH_PLACEHOLDER_STYLES } from './constants';
import {
  CONTAINER_STYLES,
  SEARCH_BOX_STYLES,
  ICON_STYLES,
  INPUT_STYLES,
} from './styles';

export function SearchBox() {
  const { refine } = useSearchBox();
  const { t } = useTranslation();
  const handleInputChange = useCallback<
    (event: ChangeEvent<HTMLInputElement>) => void
  >(
    e => {
      refine(e.target.value);
    },
    [refine],
  );
  return (
    <Box sx={CONTAINER_STYLES}>
      <Box sx={SEARCH_BOX_STYLES}>
        <Icon iconName="search" iconSx={ICON_STYLES} />
        <input
          type="text"
          className="input"
          placeholder={t(SEARCH_PLACEHOLDER_STYLES)}
          sx={INPUT_STYLES}
          onChange={handleInputChange}
        />
      </Box>
    </Box>
  );
}
