import React, { useCallback } from 'react';
import { Fragment, useState } from 'react';
import { useCurrentRefinements } from 'react-instantsearch-hooks-web';
import { Flex, ThemeUIStyleObject } from 'theme-ui';
import { FACETS, SORT_ATTRIBUTE_KEY } from '../constants';
import { FilterScreen } from './FilterScreen';
import { DrawerOption } from './DrawerOption';

const DRAWER_STYLES: ThemeUIStyleObject = {
  display: ['flex', null, null, 'none'],
  position: 'fixed',
  bottom: 0,
  width: '100%',
  height: '40px',
  backgroundColor: '#2E3841',
  zIndex: 1,
};

const FILTER_DRAWER_OPTIONS: {
  label: string;
  icon: string;
  initialAttributeKey: string;
  isFilterOption?: boolean;
}[] = [
  {
    label: 'Sort by',
    icon: 'sort',
    initialAttributeKey: SORT_ATTRIBUTE_KEY,
  },
  {
    label: 'Filter',
    icon: 'filterLines',
    initialAttributeKey: FACETS[0].attributeKey,
    isFilterOption: true,
  },
];

export const FilterDrawer = () => {
  const [initialAttributeKey, setInitialAttributeKey] = useState<string | null>(
    null,
  );

  const { items } = useCurrentRefinements();

  const handleClose = useCallback(
    () => setInitialAttributeKey(null),
    [setInitialAttributeKey],
  );

  return (
    <Fragment>
      <Flex sx={DRAWER_STYLES}>
        {FILTER_DRAWER_OPTIONS.map(option => {
          return (
            <DrawerOption
              key={option.initialAttributeKey}
              {...option}
              onClick={setInitialAttributeKey}
              showActiveIndicator={option.isFilterOption && items.length > 0}
            />
          );
        })}
      </Flex>
      <FilterScreen
        initialAttributeKey={initialAttributeKey}
        onClose={handleClose}
      />
    </Fragment>
  );
};
