import React from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import { Box, ThemeUIStyleObject } from 'theme-ui';

const CHILDREN_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: 'none',
};
export function NoResultsBoundary({
  children,
  fallback,
}: {
  children: React.ReactNode;
  fallback: React.ReactNode;
}) {
  const { results } = useInstantSearch();

  const showFallbackUI = !results.__isArtificial && results.nbHits === 0;
  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned yet.
  return (
    <React.Fragment>
      <Box sx={showFallbackUI ? undefined : CHILDREN_CONTAINER_STYLES}>
        {fallback}
      </Box>
      <Box sx={showFallbackUI ? CHILDREN_CONTAINER_STYLES : undefined}>
        {children}
      </Box>
    </React.Fragment>
  );
}
