import React from 'react';
import { Box } from 'theme-ui';

const NOTCH_STYLES = {
  height: '40px',
  width: '100%',
  borderBlockEnd: '1px solid #F1F1F1',
  paddingInline: '16px',
};
export const TopNavNotch = <Box sx={NOTCH_STYLES} />;
