/** @jsx jsx */
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { useCallback } from 'react';
import { useSortBy } from 'react-instantsearch-hooks-web';
import { Box, Flex, jsx, Label, Text } from 'theme-ui';
import { SORTS, SORT_ATTRIBUTE_KEY, SORT_LABEL } from '../../constants';
import {
  CONTAINER_STYLES,
  BUTTON_STYLES,
  LABEL_CONTAINER_STYLES,
  DROPDOWN_ARROW_STYLES,
  ACTIVE_FILTER_DROPDOWN_STYLES,
  INACTIVE_FILTER_DROPDOWN_STYLES,
  FILTER_LABEL_STYLES,
  FILTER_CHECKBOX_STYLES,
  FILTER_LABEL_TEXT_STYLES,
} from './styles';

const SortDropdown = ({
  isDropdownOpen,
  onTriggerClick,
}: {
  isDropdownOpen: boolean;
  onTriggerClick: (key: string) => void;
}) => {
  const { options, refine, currentRefinement } = useSortBy({ items: SORTS });
  const handleTriggerClick = useCallback(
    () => onTriggerClick(SORT_ATTRIBUTE_KEY),
    [onTriggerClick],
  );
  return (
    <Box sx={CONTAINER_STYLES}>
      <Flex as="button" sx={BUTTON_STYLES} onClick={handleTriggerClick}>
        <Box sx={LABEL_CONTAINER_STYLES}>{SORT_LABEL}</Box>
        <Icon iconName="chevron" iconSx={DROPDOWN_ARROW_STYLES} />
      </Flex>
      <Flex
        sx={
          isDropdownOpen
            ? ACTIVE_FILTER_DROPDOWN_STYLES
            : INACTIVE_FILTER_DROPDOWN_STYLES
        }
      >
        {options.map(option => {
          return (
            <Label key={option.value} sx={FILTER_LABEL_STYLES}>
              <input
                sx={FILTER_CHECKBOX_STYLES}
                checked={currentRefinement === option.value}
                type="checkbox"
                value={option.value}
                onChange={event => {
                  refine(event.target.value);
                }}
              />
              <Text sx={FILTER_LABEL_TEXT_STYLES}>{option.label}</Text>
            </Label>
          );
        })}
      </Flex>
    </Box>
  );
};

export default SortDropdown;
