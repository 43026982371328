export const CONTAINER_STYLES = {
  maxWidth: '736px',
  marginInline: 'auto',
};
export const SEARCH_BOX_STYLES = {
  display: 'flex',
  borderRadius: '8px',
  marginInline: ['22px', '80px'],
  padding: '12px 24px',
  gap: '24px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  border: '1px solid #DBDBDB',
};
export const ICON_STYLES = {
  width: '24px',
  height: '24px',
};
export const INPUT_STYLES = {
  backgroundColor: 'transparent',
  outline: 'none',
  border: 'none',
  width: '100%',
  fontWeight: '400',
  fontSize: '18px',
  lineHeight: '26px',
  padding: 0,
  '&:placeholder': {
    color: '#A0A0A8',
  },
};
