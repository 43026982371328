import React from 'react';
import { useState } from 'react';
import { useCurrentRefinements } from 'react-instantsearch-hooks-web';
import { Box, Flex } from 'theme-ui';
import { FACETS, SORT_ATTRIBUTE_KEY, SORT_LABEL } from '../../constants';
import { FilterMenuOptionList } from '../FilterMenuOptionList';
import { SortMenuOptionList } from '../SortMenuOptionList';
import { FilterMenuButton } from '../FilterMenuButton';
import {
  CONTAINER_STYLES,
  MENU_BUTTON_LIST_CONTAINER_STYLES,
  OPTION_LIST_CONTAINER_STYLES,
} from './styles';

export const FilterMenu = ({
  initialAttributeKey,
}: {
  initialAttributeKey: string;
}) => {
  const { items } = useCurrentRefinements();

  const [activeFilterAttributeKey, setActiveFilterAttributeKey] =
    useState(initialAttributeKey);

  const [prevInitialAttributeKey, setPrevInitialAttributeKey] =
    useState(initialAttributeKey);
  if (initialAttributeKey !== prevInitialAttributeKey) {
    setPrevInitialAttributeKey(initialAttributeKey);
    setActiveFilterAttributeKey(initialAttributeKey);
  }

  return (
    <Flex sx={CONTAINER_STYLES}>
      <Box sx={MENU_BUTTON_LIST_CONTAINER_STYLES}>
        {FACETS.map(facet => {
          const isActive = activeFilterAttributeKey === facet.attributeKey;
          const isApplied = items.some(
            item => item.attribute === facet.attributeKey,
          );
          return (
            <FilterMenuButton
              key={facet.attributeKey}
              label={facet.label}
              attributeKey={facet.attributeKey}
              isActive={isActive}
              isApplied={isApplied}
              onClick={setActiveFilterAttributeKey}
            />
          );
        })}
        <FilterMenuButton
          label={SORT_LABEL}
          attributeKey={SORT_ATTRIBUTE_KEY}
          isActive={activeFilterAttributeKey === SORT_ATTRIBUTE_KEY}
          onClick={setActiveFilterAttributeKey}
        />
      </Box>
      <Box sx={OPTION_LIST_CONTAINER_STYLES}>
        {FACETS.map(facet => {
          const isActive = facet.attributeKey === activeFilterAttributeKey;
          return (
            <FilterMenuOptionList
              key={facet.attributeKey}
              attributeKey={facet.attributeKey}
              isActive={isActive}
            />
          );
        })}
        <SortMenuOptionList
          isActive={activeFilterAttributeKey === SORT_ATTRIBUTE_KEY}
        />
      </Box>
    </Flex>
  );
};
