/** @jsx jsx */
import { Container } from '@sprinklr/shared-lib';
import {
  buttonReset,
  sharedButtonStyles,
} from '@sprinklr/shared-lib/components/button/styles';
import { useCallback, useRef, useState } from 'react';
import { useClearRefinements } from 'react-instantsearch-hooks-web';
import { useClickAway } from 'react-use';
import { Flex, jsx, ThemeUIStyleObject } from 'theme-ui';
import { FACETS, SORT_ATTRIBUTE_KEY } from '../constants';
import FilterDropdown from './FilterDropdown';
import SortDropdown from './SortDropdown';

const CONTAINER_SX = {
  py: 0,
};
const FILTERS_CONTAINER_SX = {
  justifyContent: 'space-between',
};
const CLEAR_FILTER_BUTTON_SX: ThemeUIStyleObject = {
  ...buttonReset,
  ...sharedButtonStyles,
  borderRadius: '50px',
  letterSpacing: '0.4px',
  textTransform: 'uppercase',
  fontSize: '12px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: 'primary',
  border: '2px solid',
  borderColor: 'primary',
  marginBlock: '4px',
  padding: ['4px 18px', '4px 18px'],
};

const FILTERS_MENU_BAR_STYLES: ThemeUIStyleObject = {
  gap: '2px',
};

const CLEAR_FILTER_BUTTON_LABEL = 'Clear All';

export const FiltersBar = ({
  onDropdown,
}: {
  onDropdown: (dropdownActive: boolean) => void;
}) => {
  const { refine } = useClearRefinements();
  const filterBarRef = useRef<HTMLDivElement>(null);
  const [activeDropdownAttributeKey, setActiveDropdownAttributeKey] = useState<
    string | null
  >(null);

  const handleDropdownToggle = useCallback(
    (attributeKey: string) => {
      if (
        activeDropdownAttributeKey &&
        activeDropdownAttributeKey === attributeKey
      ) {
        setActiveDropdownAttributeKey(null);
        onDropdown(false);
      } else {
        setActiveDropdownAttributeKey(attributeKey);
        onDropdown(true);
      }
    },
    [activeDropdownAttributeKey, setActiveDropdownAttributeKey, onDropdown],
  );

  const handleDropdownClose = useCallback(() => {
    setActiveDropdownAttributeKey(null);
    onDropdown(false);
  }, [setActiveDropdownAttributeKey, onDropdown]);

  useClickAway(filterBarRef, handleDropdownClose);

  return (
    <Container containerSx={CONTAINER_SX}>
      <Flex sx={FILTERS_CONTAINER_SX}>
        <Flex ref={filterBarRef} sx={FILTERS_MENU_BAR_STYLES}>
          {FACETS.map(facet => (
            <FilterDropdown
              key={facet.attributeKey}
              facet={facet}
              isDropdownOpen={facet.attributeKey === activeDropdownAttributeKey}
              onTriggerClick={handleDropdownToggle}
            />
          ))}
          <SortDropdown
            isDropdownOpen={activeDropdownAttributeKey === SORT_ATTRIBUTE_KEY}
            onTriggerClick={handleDropdownToggle}
          />
        </Flex>
        <button sx={CLEAR_FILTER_BUTTON_SX} onClick={refine}>
          {CLEAR_FILTER_BUTTON_LABEL}
        </button>
      </Flex>
    </Container>
  );
};
