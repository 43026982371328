import React from 'react';
import { useRefinementList } from 'react-instantsearch-hooks-web';
import { MenuOptionList } from './MenuOptionList';

export const FilterMenuOptionList = ({
  attributeKey,
  isActive,
}: {
  attributeKey: string;
  isActive: boolean;
}) => {
  const { items, refine } = useRefinementList({
    attribute: attributeKey,
    limit: 100,
  });
  return <MenuOptionList isActive={isActive} items={items} onChange={refine} />;
};
