/** @jsx jsx */
import { CardGrid, Container, StoryCard } from '@sprinklr/shared-lib';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Highlight, useHits } from 'react-instantsearch-hooks-web';
import { jsx } from 'theme-ui';

const CONTAINER_STYLES = {
  paddingBlockStart: '32px',
};

type CustomerStoriesHit = {
  title: string;
  description: string;
  url: string;
  previewImage: {
    gatsbyImageData: IGatsbyImageData;
  };
};

export function ResultsGrid() {
  const { hits } = useHits<CustomerStoriesHit>();
  return (
    <Container containerSx={CONTAINER_STYLES}>
      <CardGrid>
        {hits.map(hit => {
          const title = <Highlight attribute={'title'} hit={hit} />;
          return (
            <StoryCard
              key={hit.objectID}
              title={title}
              description={hit.description}
              slug={hit.url}
              previewGatsbyImage={hit?.previewImage?.gatsbyImageData ?? null}
              previewGatsbyImageAlt={hit.title}
            />
          );
        })}
      </CardGrid>
    </Container>
  );
}
