import React, { useMemo } from 'react';
import { useSortBy } from 'react-instantsearch-hooks-web';
import { SORTS } from '../constants';
import { MenuOption } from '../types';
import { MenuOptionList } from './MenuOptionList';

export const SortMenuOptionList = ({ isActive }: { isActive: boolean }) => {
  const { options, refine, currentRefinement } = useSortBy({
    items: SORTS,
  });
  const items: MenuOption[] = useMemo(
    () =>
      options.map(option => ({
        label: option.label,
        value: option.value,
        isRefined: currentRefinement === option.value,
      })),
    [options, currentRefinement],
  );
  return <MenuOptionList isActive={isActive} items={items} onChange={refine} />;
};
