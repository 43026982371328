import { ThemeUIStyleObject } from 'theme-ui';

export const FILTERS_BAR_CONTAINER_STYLES: ThemeUIStyleObject = {
  marginBlockStart: '64px',
  display: ['none', null, null, 'block'],
};
export const RESULTS_GRID_CONTAINER_STYLES: ThemeUIStyleObject = {
  position: 'relative',
  paddingBlockEnd: '32px',
  minHeight: '500px',
};

export const OVERLAY_STYLES: ThemeUIStyleObject = {
  display: ['none', null, null, 'block'],
  position: 'absolute',
  backgroundColor: 'rgba(97, 96, 96, 0.51)',
  inset: 0,
};

export const APPLIED_FILTER_LABELS_STYLES: ThemeUIStyleObject = {
  display: ['block', null, null, 'none'],
};
