import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import { ThemeUIStyleObject } from 'theme-ui';

export const CONTAINER_STYLES: ThemeUIStyleObject = {
  position: 'relative',
  height: '100%',
  backgroundColor: '#ffffff',
};
export const BUTTON_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  height: '100%',
  alignItems: 'center',
  gap: '8px',
  padding: '12px 16px',
  cursor: 'pointer',
};
export const LABEL_CONTAINER_STYLES: ThemeUIStyleObject = {
  fontSize: '18px',
  position: 'relative',
};
export const SELECTED_COUNT_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(100%, -50%)',
  aspectRatio: 1,
  height: '12px',
  borderRadius: '50%',
  fontSize: '8px',
  fontWeight: '500',
  lineHeight: 1,
  backgroundColor: '#2E3841',
  color: 'white',
};
export const DROPDOWN_ARROW_STYLES: ThemeUIStyleObject = {
  height: '4px',
};

const BASE_FILTER_DROPDOWN_STYLES: ThemeUIStyleObject = {
  position: 'absolute',
  top: 'calc(100% + 20px)',
  zIndex: 1,
  overflow: 'hidden',
  borderRadius: '12px',
  background: '#ffffff',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  '.filter-list': {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  '.filter-count': {
    display: 'none',
  },
  '.filter-label-text': {
    whiteSpace: 'nowrap',
    fontSize: '16px',
  },
  '.filter-label': {
    display: 'flex',
    gap: '16px',
    cursor: 'pointer',
    alignItems: 'center',
  },
  '.filter-checkbox': {
    backgroundColor: '#F1F1F1',
    height: '18px',
    width: '18px',
  },
};

export const FILTER_LIST_CONTAINER: ThemeUIStyleObject = {
  maxHeight: '200px',
  overflow: 'auto',
  marginBlock: '16px',
  marginInlineEnd: '8px',
  paddingInline: '16px 40px',
  scrollbarWidth: 'thin',
  '::-webkit-scrollbar': {
    width: '10px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#F1F1F1',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'primary',
    borderRadius: '8px',
    overflow: 'hidden',
  },
};

export const ACTIVE_FILTER_DROPDOWN_STYLES: ThemeUIStyleObject = {
  ...BASE_FILTER_DROPDOWN_STYLES,
  display: 'block',
};

export const INACTIVE_FILTER_DROPDOWN_STYLES: ThemeUIStyleObject = {
  ...BASE_FILTER_DROPDOWN_STYLES,
  display: 'none',
};

export const getFilterDropdownStyles = (
  isMenuOpen: boolean,
): ThemeUIStyleObject => {
  return isMenuOpen
    ? ACTIVE_FILTER_DROPDOWN_STYLES
    : INACTIVE_FILTER_DROPDOWN_STYLES;
};
