import { Icon } from '@sprinklr/shared-lib/components/icon';
import React from 'react';
import { Box, Container, Flex } from 'theme-ui';
import {
  INNER_CONTAINER_STYLES,
  ICON_STYLES,
  TITLE_STYLES,
  SUBTITLE_STYLES,
} from './styles';
import { TITLE_TEXT, SUBTITLE_TEXT } from './constants';
import { useTranslation } from 'react-i18next';

export const NoResultsFallback = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Flex sx={INNER_CONTAINER_STYLES}>
        <Icon iconName="resultsNotFound" iconSx={ICON_STYLES} />
        <Box sx={TITLE_STYLES}>{t(TITLE_TEXT)}</Box>
        <Box sx={SUBTITLE_STYLES}>{t(SUBTITLE_TEXT)}</Box>
      </Flex>
    </Container>
  );
};
