/** @jsx jsx */
import { useMemo, useCallback } from 'react';
import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import { Box, jsx, ThemeUIStyleObject } from 'theme-ui';
import { ActiveIndicator } from './ActiveIndicator';

export const FilterMenuButton = ({
  label,
  attributeKey,
  isActive,
  isApplied = false,
  onClick,
}: {
  label: string;
  attributeKey: string;
  isActive: boolean;
  isApplied?: boolean;
  onClick: (attributeKey: string) => void;
}) => {
  const handleClick = useCallback(() => {
    onClick(attributeKey);
  }, [attributeKey, onClick]);

  const buttonStyles = useMemo<ThemeUIStyleObject>(
    () => ({
      ...buttonReset,
      textAlign: 'left',
      paddingInline: '16px',
      paddingBlock: '10px',
      width: '100%',
      fontSize: '13px',
      backgroundColor: isActive ? 'white' : 'initial',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    }),
    [isActive],
  );
  return (
    <button sx={buttonStyles} onClick={handleClick}>
      <Box>{label}</Box>
      {isApplied ? ActiveIndicator : null}
    </button>
  );
};
