/** @jsx jsx */
import { useCallback, MutableRefObject } from 'react';
import {
  buttonReset,
  sharedButtonStyles,
} from '@sprinklr/shared-lib/components/button/styles';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { useMemo } from 'react';
import { jsx, ThemeUIStyleObject } from 'theme-ui';

const BUTTON_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  ...sharedButtonStyles,
  variant: 'buttons.primary',
  marginBlock: '4px',
  marginInline: 'auto',
  position: 'sticky',
  bottom: '32px',
  alignItems: 'center',
  gap: '8px',
  display: ['none', null, null, 'flex'],
};

const ICON_STYLES = {
  fill: 'white',
  height: '6px',
  transform: 'rotate(180deg)',
};

const LABEL_TEXT = 'Back To Top';

export const BackToTopCTA = ({
  isVisible,
  scrollToRef,
}: {
  isVisible: boolean;
  scrollToRef: MutableRefObject<HTMLElement | null>;
}) => {
  const handleClick = useCallback(() => {
    scrollToRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [scrollToRef]);

  const buttonStyles = useMemo<ThemeUIStyleObject>(
    () => ({
      ...BUTTON_STYLES,
      opacity: isVisible ? 1 : 0,
      pointerEvents: isVisible ? 'all' : 'none',
    }),
    [isVisible],
  );
  return (
    <button sx={buttonStyles} onClick={handleClick}>
      <div>{LABEL_TEXT}</div>
      <Icon iconName="chevron" iconSx={ICON_STYLES} />
    </button>
  );
};
