import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';
import algoliasearch from 'algoliasearch/lite';

let searchClient;

export const getSearchClient = () => {
  if (searchClient) {
    return searchClient;
  }

  if (isWindowDefined()) {
    const { app_id, search_key } = window?.spr_algolia ?? {};
    return (searchClient = algoliasearch(app_id, search_key));
  }

  return null;
};
