import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import React, { useCallback } from 'react';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';
import { ActiveIndicator } from './ActiveIndicator';

const DRAWER_OPTION_CONTAINER_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  flex: 1,
  color: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
};
const ICON_STYLES: ThemeUIStyleObject = { width: '10px' };
const LABEL_STYLES: ThemeUIStyleObject = {
  textTransform: 'uppercase',
  alignItems: 'center',
  gap: '4px',
};
export const DrawerOption = ({
  label,
  icon,
  initialAttributeKey,
  showActiveIndicator = false,
  onClick,
}: {
  label: string;
  icon: string;
  initialAttributeKey: string;
  showActiveIndicator?: boolean;
  onClick: (initialAttributeKey: string) => void;
}) => {
  const handleClick = useCallback(
    () => onClick(initialAttributeKey),
    [onClick, initialAttributeKey],
  );
  return (
    <Flex as="button" sx={DRAWER_OPTION_CONTAINER_STYLES} onClick={handleClick}>
      <Icon iconName={icon} iconSx={ICON_STYLES} />
      <Flex sx={LABEL_STYLES}>
        <Box>{label}</Box>
        {showActiveIndicator ? ActiveIndicator : null}
      </Flex>
    </Flex>
  );
};
