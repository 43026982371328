import {
  FacetConfig,
  SortConfig,
} from './components/CustomerStoriesGrid/types';

export const SORT_LABEL = 'Sort';
export const SORT_ATTRIBUTE_KEY = 'sort';

export const HITS_PER_PAGE = 100;
export const DEFAULT_SEARCH_INDEX = 'customer-stories-v1';

export const FACETS: FacetConfig[] = [
  {
    label: 'Products',
    attributeKey: 'productsUsed',
    operator: 'or',
  },
  {
    label: 'Industry',
    attributeKey: 'customerIndustry',
    operator: 'or',
  },
  {
    label: 'Company Size',
    attributeKey: 'customerCompanySizeClass',
    operator: 'or',
  },
  {
    label: 'Region',
    attributeKey: 'customerRegion',
    operator: 'or',
  },
];

export const SORTS: SortConfig[] = [
  {
    label: 'Newest - Oldest',
    value: 'customer-stories-v1',
  },
  {
    label: 'Company name A - Z',
    value: 'customer-stories-v1-alphabetical',
  },
];

export const NO_LOCALE_PADDING = { top: 'SMALL', bottom: 'SMALL' } as const;
