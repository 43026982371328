import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

const INDICATOR_STYLES: ThemeUIStyleObject = {
  backgroundColor: '#70BF54',
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  flexShrink: 0,
};

export const ActiveIndicator = <Box sx={INDICATOR_STYLES} />;
