/** @jsx jsx */
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { useMemo } from 'react';
import {
  RefinementList,
  useCurrentRefinements,
} from 'react-instantsearch-hooks-web';
import { Box, Flex, jsx } from 'theme-ui';
import { FacetConfig } from '../CustomerStoriesGrid/types';
import { FILTER_UI_CLASSNAMES, FILTER_MAX_VALUES_TO_SHOW } from './constants';
import {
  BUTTON_STYLES,
  CONTAINER_STYLES,
  DROPDOWN_ARROW_STYLES,
  getFilterDropdownStyles,
  LABEL_CONTAINER_STYLES,
  SELECTED_COUNT_STYLES,
  FILTER_LIST_CONTAINER,
} from './styles';

const FilterDropdown = ({
  facet: { attributeKey, label, operator },
  isDropdownOpen,
  onTriggerClick,
}: {
  facet: FacetConfig;
  isDropdownOpen: boolean;
  onTriggerClick: (attributeKey: string) => void;
}) => {
  const { items } = useCurrentRefinements({
    includedAttributes: [attributeKey],
  });

  const handleMenuToggle = () => {
    onTriggerClick(attributeKey);
  };

  const selectedFacetsCount = items[0]?.refinements.length ?? 0;

  const filterDropdownStyles = useMemo(
    () => getFilterDropdownStyles(isDropdownOpen),
    [isDropdownOpen],
  );
  return (
    <Box sx={CONTAINER_STYLES}>
      <Flex as="button" sx={BUTTON_STYLES} onClick={handleMenuToggle}>
        <Box sx={LABEL_CONTAINER_STYLES}>
          {label}
          {selectedFacetsCount ? (
            <Box sx={SELECTED_COUNT_STYLES}>{selectedFacetsCount}</Box>
          ) : null}
        </Box>
        <Icon iconName="chevron" iconSx={DROPDOWN_ARROW_STYLES} />
      </Flex>
      <Flex sx={filterDropdownStyles}>
        <Box sx={FILTER_LIST_CONTAINER}>
          <RefinementList
            attribute={attributeKey}
            operator={operator}
            classNames={FILTER_UI_CLASSNAMES}
            limit={FILTER_MAX_VALUES_TO_SHOW}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default FilterDropdown;
