/** @jsx jsx */
import { useEffect, useMemo } from 'react';
import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import { useClearRefinements } from 'react-instantsearch-hooks-web';
import { Flex, jsx, ThemeUIStyleObject } from 'theme-ui';
import { FACETS } from '../constants';
import FilterMenu from './FilterMenu';
import { TopNavNotch } from './TopNavNotch';

const BUTTONS_CONTAINER_STYLES: ThemeUIStyleObject = {
  width: '100%',
  justifyContent: 'center',
  gap: '10px',
  padding: '10px',
};
const SECONDARY_BUTTON_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '40px',
  border: '1px solid #2E3841',
  borderRadius: '6px',
  color: '#2E3841',
  flex: 1,
};
const PRIMARY_BUTTON_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '40px',
  border: '1px solid #FFFFFF',
  borderRadius: '6px',
  flex: 1,
  backgroundColor: '#2E3841',
  color: '#FFFFFF',
};
export const FilterScreen = ({
  initialAttributeKey,
  onClose,
}: {
  initialAttributeKey: string | null;
  onClose: () => void;
}) => {
  const { refine } = useClearRefinements();
  const isScreenVisible = initialAttributeKey !== null;

  useEffect(() => {
    const initialOverflowValue = document.body.style.overflow;
    document.body.style.overflow = isScreenVisible
      ? 'hidden'
      : initialOverflowValue;
    return () => {
      document.body.style.overflow = initialOverflowValue;
    };
  }, [isScreenVisible]);

  const containerStyles: ThemeUIStyleObject = useMemo(
    () => ({
      position: 'fixed',
      inset: 0,
      zIndex: 100,
      backgroundColor: 'white',
      height: '100vh',
      width: '100vw',
      display: isScreenVisible ? undefined : 'none',
      flexDirection: 'column',
    }),
    [isScreenVisible],
  );
  return (
    <Flex sx={containerStyles}>
      {TopNavNotch}
      <FilterMenu
        initialAttributeKey={initialAttributeKey ?? FACETS[0].attributeKey}
      />
      <Flex sx={BUTTONS_CONTAINER_STYLES}>
        <button sx={SECONDARY_BUTTON_STYLES} onClick={refine}>
          Clear All
        </button>
        <button sx={PRIMARY_BUTTON_STYLES} onClick={onClose}>
          Go Back
        </button>
      </Flex>
    </Flex>
  );
};
