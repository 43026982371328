import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import { ThemeUIStyleObject } from 'theme-ui';

export const CONTAINER_STYLES: ThemeUIStyleObject = {
  position: 'relative',
  height: '100%',
  backgroundColor: '#ffffff',
};
export const BUTTON_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  height: '100%',
  alignItems: 'center',
  gap: '8px',
  padding: '12px 16px',
  cursor: 'pointer',
};
export const LABEL_CONTAINER_STYLES: ThemeUIStyleObject = {
  fontSize: '18px',
  position: 'relative',
};
export const DROPDOWN_ARROW_STYLES: ThemeUIStyleObject = {
  height: '4px',
};
export const FILTER_LABEL_STYLES = {
  display: 'flex',
  gap: '16px',
  cursor: 'pointer',
  alignItems: 'center',
};
export const FILTER_CHECKBOX_STYLES = {
  backgroundColor: '#F1F1F1',
  height: '18px',
  width: '18px',
};
const BASE_FILTER_DROPDOWN_STYLES: ThemeUIStyleObject = {
  position: 'absolute',
  top: 'calc(100% + 20px)',
  zIndex: 1,
  paddingBlock: '16px',
  paddingInline: '16px 48px',
  borderRadius: '12px',
  background: '#ffffff',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  flexDirection: 'column',
  gap: '16px',
};

export const ACTIVE_FILTER_DROPDOWN_STYLES: ThemeUIStyleObject = {
  ...BASE_FILTER_DROPDOWN_STYLES,
};

export const INACTIVE_FILTER_DROPDOWN_STYLES: ThemeUIStyleObject = {
  ...BASE_FILTER_DROPDOWN_STYLES,
  display: 'none',
};
export const FILTER_LABEL_TEXT_STYLES: ThemeUIStyleObject = {
  whiteSpace: 'nowrap',
  fontSize: '16px',
  fontWeight: '400',
  color: 'text',
};
